import React, { useEffect, useState } from 'react';
import { Modal, Button, Table } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleLeft } from '@fortawesome/free-solid-svg-icons';

import api from './GoAPI';

const PreviousWordsModal = () => {
    const [show, setShow] = useState(false)
    const [solutions, setSolutions] = useState([]);
//    const [currentPage, setCurrentPage] = useState(1);
    const solutionsPerPage = 10;


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const fetchSolutions = async (pageNumber) => {
//	setCurrentPage(pageNumber);

	api.post('/previouswords', {limit: solutionsPerPage,
				    offset: ((pageNumber - 1) * solutionsPerPage)})
	    .then(res => {
		if (res.data.error) {
		    console.err(res.data.error);
		}
		else {
		    console.log("solutions", res.data.previous_words);
		    setSolutions(res.data.previous_words);
		}
	    })
	    .catch(err => {
		console.error(err);
	    });
    };

    useEffect(() => {
	fetchSolutions(1);
    }, []);

//    const totalPages = 5;


    return (
	<div>
	    
	    <FontAwesomeIcon icon={faCircleLeft} onClick={handleShow} className="previous-words-icon" />

	    <Modal show={show} onHide={handleClose} size="lg">
		<Modal.Header closeButton>
		    <Modal.Title>Previous Solutions</Modal.Title>
		</Modal.Header>
		<Modal.Body>
		    <Table striped bordered hover>
			<thead>
			    <tr><th>Puzzle #</th><th>Date</th><th>Solution</th></tr>
			</thead>
			<tbody>
			    {solutions.map((solution, index) => (
				<tr key={index}>
				    <td>{solution.word_id}</td>
				    <td>{solution.date}</td>
				    <td>{solution.word}</td>
				</tr>
			    ))}
			</tbody>
		    </Table>
		</Modal.Body>
		<Modal.Footer>
		    <Button variant="secondary" onClick={handleClose}>
			Close
		    </Button>
		</Modal.Footer>
	    </Modal>
	</div>
    );
};

export default PreviousWordsModal;
