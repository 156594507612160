import React from 'react';
import { Link } from 'react-router-dom';

function PrivacyPolicy({ inModal }) {
    return (
	<div className="privacy-policy">
	    {inModal ? "" : <h1>Privacy Policy</h1>}
	    <hr />
	    <p>
		Hey there! This privacy policy is intended to simply and transparently explain to you the sort of information collected when you visit the site, how that data is used, and what options you have regarding it. Let's jump in!</p>

	    <h4> 1. Personal Information:</h4>
	    <p>No personally identifiable data, such as your name, email address or location are stored on our servers. The site has no accounts, as we want to make playing the game as simple and as straightforward as possible.</p>
	    
	    <h4> 2. What Information We Do Collect:</h4>
	    <p><ul>
		<li><strong>Cookies and Local Storage</strong>: Cookies are small pieces of text that store information from our server onto your browser. The server may also read their contents. Local storage is a similar mechanism that allows for slightly larger, more structured content.</li>

		<li><strong>IP Addresses</strong>: An IP address is a unique address that identifies a device on the internet. They are saved in our logs, along with the time of any request made to our server.</li>
	    </ul></p>
	    
	    <h4>3. Why We Collect This Information:</h4>
	    <p><ul><li><strong>For A Better User Experience:</strong> Cookies and local storage help ensure your daily progress and specific user settings are not lost. The game can be difficult and it is common to return to the game multiple times during the day. Cookies and local storage facillitate this. In addition they're used to keep track of your day-to-day statistics, which can be a fun part of the experience to see how you stacked up against other players.</li>

		<li><strong>For Technical Reasons:</strong> IP addresses are logged by default by most websites. They are used mainly for troubleshooting and ensuring the website runs smoothly. We don't perform any analytics on them, or try to determine behaviors of usage.</li>
	      </ul></p>

	    <h4>4. Sharing Your Information:</h4>
	    <p>No information is shared with any third parties. Your day-to-day statistics may be aggregated along with the rest of the players, and shared only in such a summarized fashion with other players.</p>

	    <h4>5. Changes To This Policy:</h4>
	    <p>This policy may be updated occasionally. You can always revisit this page to find the latest information.</p>

	    <h4>6. Contact Us:</h4>
	    <p>If you have any questions regarding the privacy policy, please feel free to reach out. We're always happy to engage.</p>

	    <p>Thanks for visiting, and have fun!</p>

	    {inModal ? "" : <Link to="/">Return to Site</Link>}
	    
	</div>
    );
}

export default PrivacyPolicy;
