import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBaby } from '@fortawesome/free-solid-svg-icons';

import api from './GoAPI';

function HintMessage(guess) {

    const [hints, setHints] = useState({});

    useEffect(() => {


	console.log("hint mess", guess);
	
	const fetch = async () => {

	    api.post('/hint', { guess: guess.guess })
		.then(res => {
		    if (res.data.error) {
			console.log(res.data.error);
		    }
		    else {
			var any = false;
			var message = "Just";
			
			if (res.data.hints.deletes !== 0) {
			    message += " remove ";
			    message += res.data.hints.deletes + " ";
			    message += ((res.data.hints.deletes > 1) ? "letters" : "letter");
			    any = true;
			}
			
			if (res.data.hints.replaces !== 0) {
			    if (any) {
				if (res.data.hints.inserts !== 0) {
				    message += ", ";
				}
				else {
				    message += " and ";
				}
			    }

			    message += " replace ";
			    message += res.data.hints.replaces + " ";
			    message += ((res.data.hints.replaces > 1) ?
					(any ? "others" : "letters") : (any ? "other" : "letter"));
			    any = true;
			}

			if (res.data.hints.inserts !== 0) {
			    if (any) {
				message += " and ";
			    }

			    message += " add ";
			    message += res.data.hints.inserts + " ";
			    message += ((res.data.hints.inserts > 1) ?
					(any ? "more" : "letters") : (any ? "more" : "letter"));
			    any = true;
			}


			

			message += " to find the word!";

			setHints(prevHints => ({ ...prevHints, [guess]: message}));
		    }
		})
		.catch(err => {
		    console.log(err);
		});
	};

	if (!hints[guess]) {
	    fetch();
	}

    }, [guess, hints]);

    const message = hints[guess];

    return (
	<>{message ?
	   (<div><FontAwesomeIcon className="hint-icon" icon={faBaby} />&nbsp;{message}</div>) :
	   (<span>'Loading...'</span>)}</>
    );
}

export default HintMessage;

