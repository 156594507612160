import React, { useState, useEffect } from 'react';

function MidnightCountdown() {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
	const timer = setInterval(() => {
	    const newTimeLeft = calculateTimeLeft();
	    setTimeLeft(newTimeLeft);

	    if ((newTimeLeft.hours === 0) &&
		(newTimeLeft.minutes < 1)) {
		clearInterval(timer);
	    }
	    
	}, 1000);

	/* clean up interval on component unmount
	 */
	return () => clearInterval(timer);
    }, []);

    function calculateTimeLeft() {
	const now = new Date();
	const nowPST = new Date(
	    now.toLocaleString('en-US', {timeZone: 'America/Los_Angeles'}));

	const midnight = new Date(nowPST);

	midnight.setHours(24, 0, 0, 0); //set time to next midnight

	const difference = midnight - nowPST;

	const hoursLeft = Math.floor(difference / (1000 * 60 * 60));
	const minutesLeft = Math.floor((difference / (1000 * 60)) % 60);
	const secondsLeft = Math.floor((difference / 1000) % 60);

	return {
	    hours: hoursLeft,
	    minutes: minutesLeft,
	    seconds: secondsLeft,
	};
    }

    function refreshPage() {
	window.location.reload(false);
    }

    return (
	<span>
	    {((timeLeft.hours === 0) && (timeLeft.minutes < 1)) ? 
	     ( <button className="countdown-done-button" onClick={refreshPage}>New Game Available!</button> ) :
	     ( <span className="countdown">Next game in {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s</span> ) }
	</span>
    );
	
}

export default MidnightCountdown;

