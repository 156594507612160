import { Table } from 'react-bootstrap';

function ShowHowTable({ data }) {
        
    return (
	<>
	    <Table className="show-how-table" bordered>
		<thead>
		    <tr>
			{data && data.headers.map((headerChar, index) => (
			    <td className="show-how-cell" key={index}>{headerChar}</td>
			))}
		    </tr>
		</thead>
		
		<tbody>
		    {data && data.rows.map((row, rowIndex) => (
			<tr key={rowIndex}>
			    {row.map((cell, cellIndex) => (
				<td className={cell.class} key={cellIndex}>{cell.content}</td>
			    ))}
			</tr>
		    ))}
		</tbody>
	    </Table>
	</>
    );
}
		
export default ShowHowTable;
