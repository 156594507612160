import React, { useState, useEffect } from 'react';
import { Modal, Button, Card, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWaveSquare } from '@fortawesome/free-solid-svg-icons';
import api from './GoAPI';

function StatsModal(props) {
    const [show, setShow] = useState(false);
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const isMobile = window.innerWidth <= 768;

    
    const renderCard = (title, value, unit, units, isMobile) => (
	<Col>
	    <Card>
		<Card.Body>
		    <Card.Title className={isMobile ? "stats-title-mobile" : "stats-title"}>{title}</Card.Title>
		    <Card.Text className={isMobile ? "stats-value-mobile" : "stats-value"}> {(value !== null) &&
				 <span>{value}<br />{(value === 1) ? unit : units}</span>}
		    </Card.Text>
		</Card.Body>
	    </Card>
	</Col>
    );
    
    useEffect(() => {

	setIsLoading(true);
	
	api.post('/userstats')
	    .then(res => {
		if (res.data.error) {
		    console.log(res.data.error);
		    alert(res.data.error);
		}
		else {
		    console.log(res.data);
		    setData(res.data);
		}
	    })
	    .catch(err => {
		alert(err);
		console.log(err);
	    })
	    .finally(() => {
		setIsLoading(false);
	    });
    }, []);
    
    return (
	<>
	    <FontAwesomeIcon icon={faWaveSquare} onClick={handleShow} className="stats-icon" />

	    <Modal fullscreen={isMobile ? true : false} show={show} onHide={handleClose}>
		
		<Modal.Header closeButton>
		    <Modal.Title>Stats</Modal.Title>
		</Modal.Header>
		
		<Modal.Body style={isMobile ? {width: "100%"} : {width: 600 }}>
		    {isLoading ? <div>Loading...</div> :
		     <>
			 <Row>
			     {data && renderCard("Games Won", data.games_won, "game", "games", isMobile)}
			     {data && (data.average_win !== 0) &&
			      renderCard("Average Win", data.average_win, "guess", "guesses", isMobile)}
			 </Row>
			 
			 {data && (data.longest_streak !== 0) && 
			  <Row>
			      {renderCard("Current Streak", data.current_streak, "game", "games", isMobile)}
			      {renderCard("Longest Streak", data.longest_streak, "game", "games", isMobile)}
			  </Row>
			 }
			 
			 {data && (data.best_win !== 0) &&
			  <Row>
			      {renderCard("Best Win", data.best_win, "guess", "guesses", isMobile)}
			      {renderCard("Worst Win", data.worst_win, "guess", "guesses", isMobile)}
			  </Row>
			 }
		     </>
		    }
		</Modal.Body>

		<Modal.Footer>
		    <Button variant="secondary" onClick={handleClose}>
			Close
		    </Button>
		</Modal.Footer>

	    </Modal>
	</>
    );
}
		
export default StatsModal;
