
import React, { useState } from 'react';
import {Row, Col  } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion, faSnowman,faSnowflake,faCloudShowersHeavy,faCloudRain,
	faCloudSunRain,faCloudSun,faSun,faSunPlantWilt,faFire,
	faExplosion } from '@fortawesome/free-solid-svg-icons';


import { useSettings } from './SettingsContext';
import ShowHowModal from './ShowHowModal.js';
import HintMessage from './HintMessage.js';

export function getColor(distance)
{
    switch (distance) {
    case 10: return "freezing"; 
    case 9: return "frosty"; 
    case 8: return "chilly"; 
    case 7: return "cold"; 
    case 6: return "cool"; 
    case 5: return "mild"; 
    case 4: return "warm"; 
    case 3: return "toasty"; 
    case 2: return "hot"; 
    case 1: return "sizzling"; 
    default: return "mild"; 
    }
}

function getFlavor(distance) {

    const color_class = "fg_" + getColor(distance);
    
    switch(distance) {
    case 10:
	return <span class={color_class}><FontAwesomeIcon icon={faSnowman} />&nbsp;FREEZING</span>;
    case 9:
	return  <span class={color_class}><FontAwesomeIcon icon={faSnowflake} />&nbsp;FROSTY</span>; 
    case 8:
	return  <span class={color_class}><FontAwesomeIcon icon={faCloudShowersHeavy} />&nbsp;CHILLY</span>; 
    case 7:
	return  <span class={color_class}><FontAwesomeIcon icon={faCloudRain} />&nbsp;COLD</span>; 
    case 6:
	return  <span class={color_class}><FontAwesomeIcon icon={faCloudSunRain} />&nbsp;COOL</span>;
    case 5:
	return  <span class={color_class}><FontAwesomeIcon icon={faCloudSun} />&nbsp;MILD</span>;
    case 4:
	return  <span class={color_class}><FontAwesomeIcon icon={faSun} />&nbsp;WARM</span>;
    case 3:
	return  <span class={color_class}><FontAwesomeIcon icon={faSunPlantWilt} />&nbsp;TOASTY</span>;
    case 2:
	return  <span class={color_class}><FontAwesomeIcon icon={faFire} />&nbsp;HOT</span>;
    case 1:
	return  <span class={color_class}><FontAwesomeIcon icon={faExplosion} />&nbsp;SIZZLING</span>;

    default:
	return  <span class={color_class}><FontAwesomeIcon icon={faCloudSun} />&nbsp;MILD</span>;
    }
}

function GuessRow({ guess, distance, attempt, gameOver, latestGuess }) {

    const [showModal, setShowModal] = useState(false);
    const { settings } = useSettings();

    const MAX_DISTANCE = 10;
    const adjusted_distance = Math.min(distance, MAX_DISTANCE);
    
    return (
	<>
	    <Row className="my-2">
		<Col md={{ span: 1, offset: 4}} xs={2} >{'#' + (attempt)}</Col>
		<Col md={2} xs={5} ><span id={"guess" + (attempt)} className="text-capitalize">{guess}</span></Col>
		{gameOver
		 ? <Col md={2} xs={5} className="text-start">
		       <span className="distance-value">{distance}</span>
		       <span className="show-how" onClick={() => setShowModal(true)}>
			   <FontAwesomeIcon className="show-how-icon" icon={faCircleQuestion} />
		       </span>
		       <span className="flavor">{getFlavor(adjusted_distance)}</span>

		   </Col>
		 :<Col md={2} xs={5} className="text-start">
		      <span className="distance-value">{distance}</span>
		      <span className="flavor">{getFlavor(adjusted_distance)}</span>
		  </Col>}
	    </Row>
	    {settings.isBabyMode && latestGuess &&
	     <div className={"baby-mode-on bg_div_" + getColor(adjusted_distance)}><HintMessage guess={guess}></HintMessage></div>
	    }
	    <ShowHowModal show={showModal} guess={guess} onClose={() => setShowModal(false)} />
	</>
    );
}


export default GuessRow;

/*
 <ProgressBar now={distance} max={10} variant={color} />  

1   IGLOO: <FontAwesomeIcon icon="fa-solid fa-igloo" style={{color: "#c40e0e",}} />          FREEZING
2   SNOWFLAKE: <FontAwesomeIcon icon="fa-solid fa-snowflake" />                              FROSTY
3   SNOWMAN: <FontAwesomeIcon icon="fa-solid fa-snowman" />                                  CHILLY
4   CLOUD-SHOWERS-HEAVY: <FontAwesomeIcon icon="fa-solid fa-cloud-showers-heavy" />          COLD
5   CLOUD-RAIN: <FontAwesomeIcon icon="fa-solid fa-cloud-rain" />                            COOL
6   CLOUD-SUN-RAIN: <FontAwesomeIcon icon="fa-solid fa-cloud-sun-rain" />                    BRISK
7   CLOUD-SUN: <FontAwesomeIcon icon="fa-solid fa-cloud-sun" />                              LUKEWARM
8   SUN: <FontAwesomeIcon icon="fa-solid fa-sun" />                                          MILD
9   WIND: <FontAwesomeIcon icon="fa-solid fa-wind" />                                        WARM
10  SUN-PLANT-WILT: <FontAwesomeIcon icon="fa-solid fa-sun-plant-wilt" />                    TOASTY
11  FIRE: <FontAwesomeIcon icon="fa-solid fa-fire" />                                        HOT
12  VOLCANO: <FontAwesomeIcon icon="fa-solid fa-volcano" />                                  SWELTERING
13  HOT MUG: <FontAwesomeIcon icon="fa-solid fa-mug-hot" />                                  SIZZLING
14  BURST: <FontAwesomeIcon icon="fa-solid fa-burst" />                                      SCORCHING
15  EXPLOSION: <FontAwesomeIcon icon="fa-solid fa-explosion" />                              BLAZING
                                                                                             INCENDIARY
*/  



