import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBugs } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';

import PrivacyPolicy from './PrivacyPolicy.js';

function Footer() {
    const [showPrivacyModal, setShowPrivacyModal] = useState(false);
    const [showContactModal, setShowContactModal] = useState(false);

    const handleCloseContactModal = () => setShowContactModal(false);
    const handleShowContactModal = () => setShowContactModal(true);

    const handleClosePrivacyModal = () => setShowPrivacyModal(false);
    const handleShowPrivacyModal = () => setShowPrivacyModal(true);

    return (
	<footer className="footer" >

	    {/*<a href="#" className="footer-link" onClick={handleShowPrivacyModal}>Privacy Policy</a> */}
	    <button className="footer-link" onClick={handleShowPrivacyModal}>Privacy Policy</button>

	    <Modal show={showPrivacyModal} onHide={handleClosePrivacyModal}>
		<Modal.Header closeButton>
		    <Modal.Title>Privacy Policy</Modal.Title>
		</Modal.Header>
		<Modal.Body>
		    <PrivacyPolicy inModal={true} />
		</Modal.Body>
		<Modal.Footer>
		    <Button variant="secondary" onClick={handleClosePrivacyModal}>
			Close
		    </Button>
		</Modal.Footer>
	    </Modal> 

	    <FontAwesomeIcon icon={faBugs} className="footer-separator" />
	    
	    {/*<a href="#" className="footer-link" onClick={handleShowContactModal}>Contact</a> */}
	    <button className="footer-link" onClick={handleShowContactModal}>Contact</button>
	    
	    <Modal show={showContactModal} onHide={handleCloseContactModal}>
		<Modal.Header closeButton>
		    <Modal.Title>Contact</Modal.Title>
		</Modal.Header>
		<Modal.Body>
		    <div className="contact">
			<p>If you have any questions, concerns, inquiries, queries or any other pertitent interrogations, just hit me up, muffinchops:</p><p>mic_h_ael @ word_istance.com</p><p><small>(remove those underscores)</small></p>
		    </div>
		</Modal.Body>
		<Modal.Footer>
		    <Button variant="secondary" onClick={handleCloseContactModal}>
			Close
		    </Button>
		</Modal.Footer>
	    </Modal>
	    </footer>
    );
};

export default Footer;
