import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

import ShowHowTable from './ShowHowTable.js'
import api from './GoAPI';

function ShowHowModal({guess, show, onClose }) {

    const [data, setData] = useState(null);
    
    useEffect(() => {

	if (show) {
	    
	    api.post('/distancesteps', { guess: guess })
		.then(res => {
		    if (res.data.error) {
			console.err(res.data.error);
		    }
		    else {

			let width = (res.data.guess.length  > res.data.target.length ?
				     res.data.guess.length : res.data.target.length);

			let parsedData = {};
			parsedData.target = res.data.target;
			parsedData.distance = res.data.distance;

			parsedData.headers = [ '', ...res.data.guess.split(''),
					       ...Array(width -res.data.guess.length).fill('')];
			parsedData.rows = [];
			
			let entry = res.data.guess;
			for (let ii = 0; ii < res.data.steps.length; ii++) {
			    let step = res.data.steps[ii];
			    let parsedStep = {};

			    parsedStep.operation = step.operation;
			    parsedStep.positionAA = step.positionAA;
			    parsedStep.positionBB = step.positionBB;
			    
			    parsedStep.charAA = (step.charAA !== 0) ?
				String.fromCharCode(step.charAA) : null;

			    parsedStep.charBB = (step.charBB !== 0) ?
				String.fromCharCode(step.charBB) : null;

			    let row = [];

			    if (step.operation === "Insert") {
				row[0] = {
				    "class": "show-how-operation",
				    "content": (ii + 1) + '. Insert ' + parsedStep.charBB
				};
			    }
			    else if (step.operation === "Replace") {
				row[0] = {
				    "class": "show-how-operation",
				    "content":  (ii + 1) + '. Replace ' + parsedStep.charAA +
					" with " + parsedStep.charBB
				};
			    }
			    else if (step.operation === "Delete") {
				row[0] = {
				    "class": "show-how-operation",
				    "content": (ii + 1) + '. Delete ' + parsedStep.charAA
				};
			    }

			    for (let jj = 1; jj <= width; jj++) {

				let entryIndex = jj - 1;

				row[jj] = {
				    "content" : "",
				    "class" : "show-how-cell"
				};
				
				if ((step.operation === 'Insert') &&
				    (step.positionAA === (entryIndex))) {
				    row[jj].content = String.fromCharCode(step.charBB);
				    row[jj].class = "show-how-insert";
				    entry = entry.slice(0, entryIndex) + row[jj].content + entry.slice(entryIndex);
				    continue;
				}
				else if ((step.operation === 'Replace') &&
					 (step.positionAA === (entryIndex))) {
				    row[jj].content = String.fromCharCode(step.charBB);
				    row[jj].class = "show-how-replace";
				    entry = entry.slice(0, entryIndex) + row[jj].content + entry.slice(entryIndex + 1);
				    continue;
				}
				else if ((step.operation === 'Delete') &&
					 (step.positionAA === (entryIndex))) {
				    entry = entry.slice(0, entryIndex) + entry.slice(entryIndex + 1); // no continue
				    row[jj].class = "show-how-delete";
				    row[jj].content = entry[entryIndex];
				    continue;
				}
				else {
				    row[jj].class = "show-how-cell";
				    row[jj].content = entry[entryIndex];
				}
			    }
			    parsedData.rows.push(row);
			}

			setData(parsedData);
		    }
		})
		.catch(err => {
		    console.error(err);
		});
	}

    }, [ show, guess ]); /* only runs when show or guess changes */
    
    return (
	<>
	    <Modal dialogClassName="show-how-modal-width" show={show} onHide={onClose}>
		
		<Modal.Header closeButton>
		    {data && data.target &&
		     <Modal.Title>From {guess.toUpperCase()} to {data.target.toUpperCase()} ({data.distance} Steps)</Modal.Title>}
		</Modal.Header>

		<Modal.Body>
		    {data && <ShowHowTable data={data} />}

		</Modal.Body>

		<Modal.Footer>
		    <Button variant="secondary" onClick={onClose}>
			Close
		    </Button>
		</Modal.Footer>
	    </Modal>
	</>
    );
}
		
export default ShowHowModal;
