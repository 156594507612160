import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBaby } from '@fortawesome/free-solid-svg-icons';

import { useSettings } from './SettingsContext';
import api from './GoAPI';

function StatusBar() {

    const [showAlert, setShowAlert] = useState(false);
    const { settings, setSettings } = useSettings();

    const handleBabyModeToggle = () => {

	if (settings.gameStarted && settings.isBabyMode) {
	    setShowAlert(true);
	    return;
	}
	
	var newMode = (!settings.isBabyMode ? "baby" : "hard");
	
	api.post('/updatemode', { mode: newMode })
	    .then(res => {
		if (!res.data.error) {
		    setSettings({isBabyMode : !settings.isBabyMode,
				 isDarkMode: settings.isDarkMode,
				 gameStarted: settings.gameStarted
				});
		}})
	    .catch(err => {
		console.error(err);
	    });
    }

    
    return (
	<>
	    {settings.isBabyMode && settings.gameStarted ? 
	     <div className="status-bar-long">
		 <span onClick={handleBabyModeToggle}><FontAwesomeIcon className="hint-icon" icon={faBaby} />&nbsp;&nbsp;Hints: <span className="status-bar-value">ON</span>
		     <span className="hint-locked">&nbsp;[LOCKED]&nbsp;</span>
		 </span>
	     </div>
	     :
	     (settings.isBabyMode ? 
	      <div className="status-bar">
		  <span onClick={handleBabyModeToggle}><FontAwesomeIcon className="hint-icon" icon={faBaby} />&nbsp;&nbsp;Hints: <span className="status-bar-value">ON</span></span>
	      </div>
	      :
	      <div className="status-bar">
		  <span onClick={handleBabyModeToggle}><FontAwesomeIcon className="hint-icon" icon={faBaby} />&nbsp;&nbsp;Hints: <span className="status-bar-value">OFF</span></span>
	      </div>
	     )
	    }

	    {/*showAlert && <Alert className="hint-lock-alert" key="danger" variant="danger" onClose={() => setShowAlert(false)} dismissible>You may only turn off hints in a new game.</Alert>*/}
	</>
    );
}

export default StatusBar;
