import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareNodes } from '@fortawesome/free-solid-svg-icons';
import GuessRow, { getColor }  from './GuessRow';
import Celebration from './Celebration';
import MidnightCountdown from './MidnightCountdown';
import api from './GoAPI';

import { useSettings } from './SettingsContext';
import './App.css';

function GameBoard() {
    const [guesses, setGuesses] = useState([]);
    const [previousGuesses, setPreviousGuesses] = useState({}); 
    const [targetWord, setTargetWord] = useState(null); /* why is this null, not '' ?? */
    const [userGuess, setUserGuess] = useState('');
    const [error, setError] = useState('');
    const [hasWon, setHasWon] = useState(false);
    const [gameOver, setGameOver] = useState(false);
    const [latestGuess, setLatestGuess] = useState(null);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const { settings, setSettings } = useSettings();

    useEffect(() => {

	const start = async () => {
	    
	    api.get('/newgame')
		.then(res => {

		    if (res.data && res.data.game) {

			let game = res.data.game;

			if (game.guesses) {
			    for (var ii = 0; ii < game.guesses.length; ii++) {
				let currentData = game.guesses[ii];
				let mostRecent = (ii === (game.guesses.length - 1));
				
				const guess = {
				    guess: currentData.guess,
				    distance: currentData.distance,
				    attempt: currentData.attempt };

				setGuesses(theGuesses => ([...theGuesses, guess]));
				
				setPreviousGuesses(prevGuesses => ({
				    ...prevGuesses,
				    [currentData.guess]: currentData.attempt }));

				if (mostRecent) {
				    setLatestGuess(guess);
				}
			    }
			}

			if ((game.result === "gave_up") && (res.data.word)) {
			    setTargetWord(res.data.word);
			    setGameOver(true);
			}
			else if ((game.result === "won") && (res.data.word)) {
			    setHasWon(true);
			    setGameOver(true);
			}

			setSettings(prevSettings => ({
			    ...prevSettings,
			    isBabyMode: (game.mode === 'baby'),
			    gameStarted: (game.guesses && (game.guesses.length !== 0)),
			}));
			
		    }
		}) 
		.catch(err => console.error(err));
	};

	start();
	
	// setSettings is safe, remove below line if modifying this func extensively
	// though, to ensure no new dependencies introduced.
	
	// eslint-disable-next-line react-hooks/exhaustive-deps	
    }, []);


    const handleShare = () => {
	api.post('/sharestats')
	    .then(res => {
		console.log("share stats", res.data);

		var mode_icon = "🧠";
		if (res.data.mode === "baby") {
		    mode_icon = "👶";
		}
		    
		let message =
		    "Wordistance.com #" + res.data.word_id + " " + mode_icon + "\n\n" +
		    "🎉 " + res.data.guesses + " Guesses!\n" +
		    
		    "Penultimate Guess: " + res.data.penultimate_guess +
		    " (" + getColor(res.data.penultimate_guess) + ") \n";

		console.log(message);

		// mobile sharing
		if (navigator.share) {
		    navigator.share({
			title: "Wordistance.com #" + res.data.word_id,
			text: message,
		    }).catch((error) => console.log("Share failed", error));
		}
		else {
		    const textarea = document.createElement('textarea');
		    textarea.value = message;
		    document.body.appendChild(textarea);
		    textarea.select();
		    document.execCommand('copy');
		    document.body.removeChild(textarea);
		    alert("Results copied to clipboard");
		}
	    })
	    .catch(err => console.error(err));
    };

    const handleShareLoser = () => {
	api.post('/sharestatsloser')
	    .then(res => {
		console.log("share stats", res.data);

		var mode_icon = "🧠";
		if (res.data.mode === "baby") {
		    mode_icon = "👶";
		}

		let message =
		    "Wordistance.com #" + res.data.word_id + " " + mode_icon + "\n\n" +
		    "💔 LOSER\n" +
		    "Gave up after " + res.data.guesses + " guesses! \n" +

		    "Last Guess: " + res.data.last_guess +
		    " (" + getColor(res.data.last_guess) + ") \n";

		console.log(message);

		// mobile sharing
		if (navigator.share) {
		    navigator.share({
			title: "Wordistance.com #" + res.data.word_id,
			text: message,
		    }).catch((error) => console.log("Share failed", error));
		}
		else {
		    const textarea = document.createElement('textarea');
		    textarea.value = message;
		    document.body.appendChild(textarea);
		    textarea.select();
		    document.execCommand('copy');
		    document.body.removeChild(textarea);
		    alert("Results copied to clipboard");
		}
	    })
	    .catch(err => console.error(err));
    };

    const handleGiveUp = () => {
	setShowConfirmModal(true);
    }

    const confirmGiveUp = () => {
	api.post('/revealword')
	    .then(res => {
		setTargetWord(res.data.word)
		setGameOver(true)
	    })
	    .catch(err => console.error(err));
	
	setShowConfirmModal(false);
    };
    
    const handleGuess = (event) => {

	event.preventDefault();

	if (userGuess.length < 3) {
	    setError("Guesses must be at least 3 characters long.");
	    return;
	}

	var guessWord = userGuess.toLowerCase().trim();
	
	api.post('/guess', { guess: guessWord })
	    .then(res => {
		if (res.data.error) {
		    setError(res.data.error);
		}
		else {
		    setError(null);
		    setSettings({isBabyMode: settings.isBabyMode,
				 isDarkMode: settings.isDarkMode,
				 gameStarted: true})

		    if (guessWord in previousGuesses) {
			setLatestGuess(guesses.find(
			    (guess) => guess.attempt === previousGuesses[guessWord]
			));
		    }
		    else {
			const newGuess = {
			    guess: guessWord,
			    distance: res.data.distance,
			    attempt: (guesses.length + 1) };
			
			setLatestGuess(newGuess);
			setPreviousGuesses({ ...previousGuesses,
					     [guessWord]: newGuess.attempt });
			setGuesses(guesses.concat(newGuess));
		    }
		    
		    if (res.data.distance === 0) {
			setHasWon(true);
			setGameOver(true);
		    }
		}
	    })
	    .catch(err => {
		if (err.response && err.response.status === 400) {
		    setError(err.response.data);
		}
		else {
		    console.error(err);
		}
	    });

	setUserGuess('');
    }

    return (
	<Container>
	    <h1 className="text-center my-4 title">wordistance</h1>

	    {hasWon &&
	     <div className="win-message">
		 Congratulations!<br />You found the word in {(guesses.length)} guesses!<br />
		 <button className="share" onClick={handleShare}>Share&nbsp;<FontAwesomeIcon icon={faShareNodes} /></button><br /><MidnightCountdown /><br />
		 <span className="win-word">{latestGuess.guess}</span>
	     </div>}

	    {hasWon && <Celebration></Celebration> }

	    {!hasWon && !targetWord && 
	     <div className="input-area">
		 <form onSubmit={handleGuess}>
		     <input name="guess" className="input-guess" type="text" maxlength="30" value={userGuess} onChange={ee => setUserGuess(ee.target.value)} />
		     <button className="input-button" type="submit">Guess</button>
		 </form>
		 <button className="input-button" onClick={handleGiveUp}>Give Up</button>
	     </div>
	    }
	    
	    {targetWord &&
	     <p className="revealed-word">The word was: <span className="text-capitalize">{targetWord}</span> <br />
	     <button className="share" onClick={handleShareLoser}>Share&nbsp;<FontAwesomeIcon icon={faShareNodes} /></button><br /><MidnightCountdown /></p>
	    }
	    
	    {error && <p style={{ color: 'red', fontWeight: 'bold' }}>{error}</p>}

	    <Row className="my-2">
		<Col md={{ span: 1, offset: 4}} xs={2} ><strong>Attempt</strong></Col>
		<Col md={2} xs={5} ><strong>Guess</strong></Col>
		<Col md={2} xs={5} className="text-start"><strong>Distance</strong></Col>

	    </Row>
	    
	    {latestGuess && !hasWon &&
	     <>
		 <GuessRow gameOver={gameOver}
			   key={latestGuess.attempt}
			   latestGuess={true}
			   attempt={latestGuess.attempt}
			   guess={latestGuess.guess}
			   distance={latestGuess.distance} />
		 <hr />
	     </>}
	    
	    {guesses.sort((aa, bb) =>
		(aa.distance - bb.distance) || (bb.attempt - aa.attempt))
	     .filter((guess) => guess !== latestGuess)
		    .map((guess, ii) => <GuessRow latestGuess={false} gameOver={gameOver} key={guess.attempt} attempt={guess.attempt} guess={guess.guess} distance={guess.distance} />)}

	    <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
		<Modal.Header closeButton>
		    <Modal.Title>Really?</Modal.Title>
		</Modal.Header>
		<Modal.Body>Are you sure you want to give up?</Modal.Body>
		<Modal.Footer>
		    <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
			Cancel
		    </Button>
		    <Button variant="primary" onClick={confirmGiveUp}>
			Confirm
		    </Button>
		</Modal.Footer>
	    </Modal>
	    
	</Container>

    );
}

export default GameBoard;
