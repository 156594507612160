import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { SettingsProvider } from './SettingsContext';
import StatusBar from './StatusBar';
//import SettingsModal from './SettingsModal';
import InfoModal from './InfoModal';
import StatsModal from './StatsModal';
import PreviousWordsModal from './PreviousWordsModal';
import GameBoard from './GameBoard';
import Footer from './Footer';
import PrivacyPolicy from './PrivacyPolicy';

import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
    const [isNewUser, setIsNewUser] = useState(false);
    
    useEffect(() => {
	const firstVisit = !localStorage.getItem("first_visit");
	setIsNewUser(firstVisit);

	if (firstVisit) {
	    localStorage.setItem("first_visit", "no");
	}
    }, []);

    return (
	<Router>
	    <div className="App">
		<Routes>
		    <Route path="/privacy-policy" element={
			       <>
				   <Helmet>
				       <title>Privacy Policy</title>
				       <meta name="description" content="Privacy Policy for Wordistance" />
				   </Helmet>
				   <PrivacyPolicy />
			       </>
			   } />

		    
		    <Route path="/"
			   element={
			       <>
				   <Helmet>
				       <title>Wordistance</title>
				       <meta name="description" content="Wordistance-- how fast can you find the mystery word?" />
				       
				       <meta property="og:title" content="Wordistance" />
				       <meta property="og:description" content="Wordistance-- how fast can you find the mystery word?" />
				       <meta property="og:type" content="game" />
				       <meta property="og:url" content="https://wordistance.com" />
				       <meta property="og:image" content="https:/wordistance.com/images/wordistance_opengraph.png" />
				   </Helmet>
				   <SettingsProvider>
				       <StatusBar />
				       <InfoModal isNewUser={isNewUser} />
				       {/*<SettingsModal />*/}
				       <StatsModal />
				       <PreviousWordsModal />
				       <div className="GameBoard">
					   <GameBoard />
				       </div>
				       <Footer />
				   </SettingsProvider>
			       </>
			   }
		    />
		</Routes>
	    </div>
	</Router>
    );
}

export default App;
