import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import ShowHowTable from './ShowHowTable.js'

function InfoModal(props) {
    const [show, setShow] = useState(false);
    const [data, setData] = useState(null);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const isMobile = window.innerWidth <= 768;
    
    useEffect(() => {

	let parsedData = [];

	/* PART => PARTY
	 */
	let example1 = {};

	example1.width = 5;
	example1.distance = 1;
	example1.target = 'Party';
	example1.guess = 'Part';

	example1.headers = [ '', ...example1.guess.split(''),
			       ...Array(example1.width - example1.guess.length).fill('')];

	example1.rows = [];

	example1.rows[0] = [];
	example1.rows[0][0] = { "class" : "show-how-operation", "content" : "1. Insert Y" };
	example1.rows[0][1] = { "class" : "show-how-cell", "content" : "P" };
	example1.rows[0][2] = { "class" : "show-how-cell", "content" : "A" };
	example1.rows[0][3] = { "class" : "show-how-cell", "content" : "R" };
	example1.rows[0][4] = { "class" : "show-how-cell", "content" : "T" };
	example1.rows[0][5] = { "class" : "show-how-insert", "content" : "Y" };


	/* PART => ART
	 */

	let example2 = {};
	
	example2.width = 4;
	example2.distance = 1;
	example2.target = 'Art';
	example2.guess = 'Part';

	example2.headers = [ '', ...example2.guess.split(''),
			       ...Array(example2.width - example2.guess.length).fill('')];

	example2.rows = [];

	example2.rows[0] = [];
	example2.rows[0][0] = { "class" : "show-how-operation", "content" : "1. Delete P" };
	example2.rows[0][1] = { "class" : "show-how-delete", "content" : "" };
	example2.rows[0][2] = { "class" : "show-how-cell", "content" : "A" };
	example2.rows[0][3] = { "class" : "show-how-cell", "content" : "R" };
	example2.rows[0][4] = { "class" : "show-how-cell", "content" : "T" };
	
	/* PART => PORT
	 */

	let example3 = {};

	example3.width = 5;
	example3.distance = 1;
	example3.target = 'Port';
	example3.guess = 'Part';

	example3.headers = [ '', ...example3.guess.split(''),
			       ...Array(example3.width - example3.guess.length).fill('')];

	example3.rows = [];

	example3.rows[0] = [];
	example3.rows[0][0] = { "class" : "show-how-operation", "content" : "1. Replace A with O" };
	example3.rows[0][1] = { "class" : "show-how-cell", "content" : "P" };
	example3.rows[0][2] = { "class" : "show-how-replace", "content" : "O" };
	example3.rows[0][3] = { "class" : "show-how-cell", "content" : "R" };
	example3.rows[0][4] = { "class" : "show-how-cell", "content" : "T" };

	/* PART => CARROT
	 */
	let example4 = {};

	example4.width = 6;
	example4.distance = 3;
	example4.target = 'Carrot';
	example4.guess = 'Part';

	example4.headers = [ '', ...example4.guess.split(''),
			       ...Array(example4.width - example4.guess.length).fill('')];

	example4.rows = [];

	example4.rows[0] = [];
	example4.rows[0][0] = { "class" : "show-how-operation", "content" : "1. Insert O" };
	example4.rows[0][1] = { "class" : "show-how-cell", "content" : "P" };
	example4.rows[0][2] = { "class" : "show-how-cell", "content" : "A" };
	example4.rows[0][3] = { "class" : "show-how-cell", "content" : "R" };
	example4.rows[0][4] = { "class" : "show-how-insert", "content" : "O" };
	example4.rows[0][5] = { "class" : "show-how-cell", "content" : "T" };
	example4.rows[0][6] = { "class" : "show-how-cell", "content" : "" };

	example4.rows[1] = [];
	example4.rows[1][0] = { "class" : "show-how-operation", "content" : "1. Insert R" };
	example4.rows[1][1] = { "class" : "show-how-cell", "content" : "P" };
	example4.rows[1][2] = { "class" : "show-how-cell", "content" : "A" };
	example4.rows[1][3] = { "class" : "show-how-cell", "content" : "R" };
	example4.rows[1][4] = { "class" : "show-how-insert", "content" : "R" };
	example4.rows[1][5] = { "class" : "show-how-cell", "content" : "O" };
	example4.rows[1][6] = { "class" : "show-how-cell", "content" : "T" };

	example4.rows[2] = [];
	example4.rows[2][0] = { "class" : "show-how-operation", "content" : "1. Replace P with C" };
	example4.rows[2][1] = { "class" : "show-how-replace", "content" : "C" };
	example4.rows[2][2] = { "class" : "show-how-cell", "content" : "A" };
	example4.rows[2][3] = { "class" : "show-how-cell", "content" : "R" };
	example4.rows[2][4] = { "class" : "show-how-cell", "content" : "R" };
	example4.rows[2][5] = { "class" : "show-how-cell", "content" : "O" };
	example4.rows[2][6] = { "class" : "show-how-cell", "content" : "T" };

	parsedData[0] = example1;
	parsedData[1] = example2;
	parsedData[2] = example3;
	parsedData[3] = example4;
	
	setData(parsedData);

	setShow(props.isNewUser);
	
    }, [ props.isNewUser ]);
    
    return (
	<>
	    <FontAwesomeIcon icon={faInfoCircle} onClick={handleShow} className="info-icon" />

	    <Modal fullscreen={isMobile ? true : false} show={show} onHide={handleClose}>
		
		<Modal.Header closeButton>
		    <Modal.Title>Wordistance</Modal.Title>
		</Modal.Header>

		<Modal.Body style={isMobile ? {width: "100%"} : {width: 600 }}>
		    <h3>How to play</h3>
		    <p>Playing is simple: just keep guessing words until you find the mystery word!</p>
		    <p>Each guess will reveal a number that represents the distance between your guess and the mystery guess. The lower the number, the closer you are to the word!</p>

		    <h3>The distance</h3>
		    
		    <p>The distance is the number of steps necessary to change your guess into the mystery word. A step can take three possible forms.</p>
		    <hr />
		    
		    <p>The first thing we can do is <span className="info-command">insert</span> a letter. If we consider the word <span className="info-word">PART</span>, we can change it to the word <span className="info-word">PARTY</span> by adding the letter <span className="info-word">Y</span>. This means the distance between <span className="info-word">PART</span> and <span className="info-word">PARTY</span> is 1.</p>
		    <p>{data && data[0] && <ShowHowTable data={data[0]} />}</p>
		    <hr />
		    
		    <p>The next is <span className="info-command">deleting</span> a letter. If we again consider the word <span className="info-word">PART</span>, we can change it to the word <span className="info-word">ART</span> by removing the letter <span className="info-word">P</span>. So the distance between <span className="info-word">PART</span> and <span className="info-word">ART</span> is also 1.</p>
		    <p>{data && data[1] && <ShowHowTable data={data[1]} />}</p>
		    <hr />

		    <p>Finally, we can <span className="info-command">replace</span> letters. If we wish to change <span className="info-word">PART</span> into <span className="info-word">PORT</span> we can do so by changing the letter <span className="info-word">A</span> to the letter <span className="info-word">O</span>. Again we have a distance of 1.</p>
		    <p>{data && data[2] && <ShowHowTable data={data[2]} />}</p>
		    <hr />

		    <p>So we can see that the words <span className="info-word">PARTY</span>, <span className="info-word">ART</span> and <span className="info-word">PORT</span> all are separated from <span className="info-word">PART</span> by a distance of 1. There are many more words also 1 change away from <span className="info-word">PART</span> (e.g. <span className="info-word">PERT</span>, <span className="info-word">PAR</span>, <span className="info-word">CART</span>, <span className="info-word">PARE</span> and so forth).</p> As a final example we can consider how to change <span className="info-word">PART</span> into <span className="info-word">CARROT</span>. To do so requires at least 3 changes as you can see below:
		    <p>{data && data[3] && <ShowHowTable data={data[3]} />}</p>
		    <hr />

		    <h3>Some tips</h3>

		    <p>Ultimately this is a game of deduction. Each guess you make will give you information about the mystery word. Try guessing two similar words like <span className="info-word">CAT</span> and <span className="info-word">CUT</span>. If they have different distances from the mystery word, what does this tell you?</p>

		    <p>Don't be discouraged-- it often takes many guesses to narrow in on the word!</p>

		    <p>Incidentally this distance is known as the <i>Levenshtein Distance</i>, which is a popular metric used in Computer Science!</p>


		</Modal.Body>


		<Modal.Footer>
		    <Button variant="secondary" onClick={handleClose}>
			Close
		    </Button>
		</Modal.Footer>

	    </Modal>
	</>
    );
}
		
export default InfoModal;
